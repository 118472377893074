<template>
  <div class="container main cf">
<!--    <h2>新闻动态</h2>-->
<!--    <div class="main_left">-->
<!--      <a href="#">行业资讯</a>-->
<!--      <a href="#">公司动态</a>-->
<!--      <a href="#">媒体热点</a>-->
<!--      <img src="@/assets/images/08.jpg" />-->
<!--    </div>-->
    <dl class="main_right">
      <dt><a href="news_minute.html">迎|“十一”主题优惠即将开幕</a></dt>
      <dd class="date">2015-08-03</dd>
      <dd class="content">
        由于端午是今年上半年最后一个假期,气候相对比较舒适，正式广大消费者外出购物的好时机，值此，我公司针对“端午、六一”,也推出了多种主题优惠，同样“十一”也将推出主题优惠，来店就送气球一只，十一当天购物满3000元就送面值100元枕头一对。欢迎广大会员和新老朋友到店参观选购。
      </dd>
      <dt><a href="">有意实施行业内重组 蕴藏可升值资产</a></dt>
      <dd class="date">2015-08-03</dd>
      <dd class="content">
        近期身陷“财务造假”风波的四川XXX交出了一份亮丽的年报。4月7日，四川XXX公告称，去年实现净利润1.16亿元，同比增长272.17%。同时，公司董事会通过了以资本公积金向全体股东每10股转增5股的方案。XXX方面表示，今年在加强主业的同时，也会关注行业内实施产业重组的机会。
      </dd>
      <dt><a href="">探讨行业共赢新方向 2019盟创直播嘉年华大会完满谢幕</a></dt>
      <dd class="date">2015-08-03</dd>
      <dd class="content">
        即将到来的端午节小长假，自然少不了各大家电卖场的大力促销，由于恰逢四年一届的世界杯和刚刚结束的高考，今年的节日促销也因此显得颇具新意。据悉，面向球迷的世界杯家电降价、买赠以及针对学生消费的数码3C产品促销近日已经悄然展开。
      </dd>
      <dt>
        <a href=""
          >“连接共生·智变渠道”——2019盟创直播嘉年华将于成都探讨行业新动态</a
        >
      </dt>
      <dd class="date">2015-08-03</dd>
      <dd class="content">
        2016年被称为我国移动互联网的直播元年，直播平台、观众数量都呈现井喷式发展。近两年来直播行业用户规模增速明显放缓，特别是2018年直播行业遭受短视频行业冲击。2019年，直播的路依然会很艰难，因为比拼已经不再产品端的迭代，而是业务创新、资本输血和资源的协同性，以及如何对抗短视频井喷式发展抢夺视频消费领域的主导权。如何突破当前固有的盈利路径依赖，打破当前的内容模式与玩法陈旧的短板，是直播逆势反弹颇为关键的一环。
      </dd>
    </dl>
  </div>
</template>

<script>
export default {};
</script>

<style  scoped>
.main_left {
  float: left;
  width: 261px;
  margin-right: 28px;
}
.main_left a {
  font-size: 12px;
  width: 261px;
  height: 97px;
  display: inline-block;
  text-align: center;
  line-height: 97px;
  background: #f2f2f2;
  margin-bottom: 2px;
}
.main_left img {
  width: 261px;
  margin-top: 20px;
}
.main_right {
  float: right;
  width: 910px;
  box-sizing: border-box;
}
.main_right dt {
  padding-top: 15px;
}
.main_right dd {
  font-size: 14px;
}
.main_right dt a:hover {
  color: #00f;
}
.main_right .date {
  font-size: 10px;
  color: #a5a5b1;
  padding: 10px 0;
}
.main_right .content {
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  color: #818388;
}
</style>